$green: #00C288;
$green-10: #00c2881a;
$yellow: #FE9A0E;
$yellow-10: #fe9a0e1a;
$red: #D50D30;
$red-10: #d50d301a;
$main-10: #3FB6E81A;
$main: #3FB6E8;
$main-05: #3FB6E80D;
$main-active: #0071B9;
$main-dark: #193276;
$main-svg: invert(64%) sepia(79%) saturate(2429%) hue-rotate(171deg) brightness(106%) contrast(82%);
$white: #FFFFFF;
$neutral-10: #F5F7F9;
$neutral-100: #E6EDF2;
$neutral-200: #CCD4DE;
$neutral-400: #8998AC;
$neutral-600: #545E75;
$shadowBlack: #000000B3 0% 0% no-repeat paddingBox;
$border-1: 1px solid;
$border-2: 2px solid;
$border-3: 3px solid;
$border-4: 4px solid;
$linear-gradient-bg-light: linear-gradient(180deg, #FFF 0%, #DCE6EB 100%);
$box-shadow: #00000020;
$box-shadow-light: #ccd4de1a;
$linear-gradient-white: #ffffff00;
$transparent-linear-gradient: transparent linear-gradient(180deg, rgba(0, 113, 185, 0.05) 0%, rgba(255, 255, 255, 0.05) 100%) 0 0 no-repeat padding-box;
$default-background: #F5F7F9;
$modal-background: #f5f7f9d9;
$disabled-color: #BDBDBD;

:export {
  green: $green;
  green10: $green-10;
  yellow: $yellow;
  yellow10: $yellow-10;
  red: $red;
  red10: $red-10;
  main10: $main-10;
  main: $main;
  main05: $main-05;
  mainActive: $main-active;
  mainDark: $main-dark;
  white: $white;
  neutral10: $neutral-10;
  neutral100: $neutral-100;
  neutral200: $neutral-200;
  neutral400: $neutral-400;
  neutral600: $neutral-600;
  shadowBlack: $shadowBlack;
  border1: $border-1;
  border2: $border-2;
  border3: $border-3;
  border4: $border-4;
  linearGradientBgLight: $linear-gradient-bg-light;
  boxShadow: $box-shadow;
  boxShadowLight: $box-shadow-light;
  linearGradientWhite: $linear-gradient-white;
  transparentLinearGradient: $transparent-linear-gradient;
  defaultBackground: $default-background;
  mainSvg: $main-svg;
  modalBackground: $modal-background;
  disabledColor: $disabled-color;
}

body {
  margin: 0;
  background-color: $default-background !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.navbar {
  bottom: 0 !important;
  position: absolute !important;
  width: 100%;
}

.navbar a {
  color: $white;
}

.navbar a:hover {
  color: $neutral-100;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: $neutral-600;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: $white;
}

.App-link {
  color: $main;
}

.align-center {
  margin: 0 auto !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.centered-label {
  margin: 0 auto;
  width: 100%;
  text-align: center;
}

.btn-custom {
  padding: 16px 64px 17px 64px !important;
  font: normal normal bold 16px/20px Inter !important;
  letter-spacing: 0 !important;
  opacity: 1;
  border: $border-2 $main !important;
  background-color: $main !important;
  border-radius: 8px !important;
}

.btn-primary-custom {
  padding: 16px 64px 17px 64px !important;
  font: normal normal bold 16px/20px Inter !important;
  letter-spacing: 0 !important;
  opacity: 1;
  border: $border-2 $main !important;
  color: $main !important;
  background-color: transparent !important;
  border-radius: 8px !important;
}

.btn-custom-disable {
  padding: 16px 64px 17px 64px !important;
  font: normal normal bold 16px/20px Inter !important;
  letter-spacing: 0 !important;
  opacity: 1;
  border: $border-2 $neutral-200 !important;
  background-color: $neutral-200 !important;
  border-radius: 8px !important;
}

.btn-custom-disable-2 {
  padding: 16px 64px 17px 64px !important;
  font: normal normal bold 16px/20px Inter !important;
  letter-spacing: 0 !important;
  opacity: 1;
  border: $border-2 $neutral-200 !important;
  background-color: transparent !important;
  border-radius: 8px !important;
}

.btn-custom-2 {
  padding: 16px 48px 17px 48px !important;
  font: normal normal bold 16px/20px Inter !important;
  letter-spacing: 0 !important;
  opacity: 1;
  border: $border-2 $main !important;
  background-color: $main !important;
  border-radius: 8px !important;
}

.btn-custom-no-padding {
  padding: 16px 0 17px 0 !important;
  font: normal normal bold 16px/20px Inter !important;
  letter-spacing: 0 !important;
  opacity: 1;
  border: $border-2 $main !important;
  background-color: $main !important;
  border-radius: 8px !important;
}

.btn-custom-3 {
  padding: 16px 20px 17px 20px !important;
  font: normal normal bold 16px/20px Inter !important;
  letter-spacing: 0 !important;
  opacity: 1;
  border: $border-2 $main !important;
  background-color: $main !important;
  border-radius: 8px !important;
}

.btn-custom-danger {
  padding: 16px 20px 17px 20px !important;
  font: normal normal bold 16px/20px Inter !important;
  letter-spacing: 0 !important;
  opacity: 1;
  border: $border-2 $red !important;
  border-radius: 8px !important;
}

.wide-logo {
  margin: 10% auto 2% auto;
}

#togglePassword {
  margin-left: -30px;
  cursor: pointer;
}

.wide-logo-png {
  margin-top: -25px;
  width: 70px;
}

.nav-link {
  font-size: 10px;
}

.nav-icon {
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.f-30 {
  font-size: 30px;
  color: black;
}

.logout-btn {
  background-color: $neutral-400 !important;
  border-color: $neutral-400 !important;
  height: 45px;
}

.version-label {
  font-weight: 400;
  font-size: 12px;
  color: $neutral-400;
}

.list-label {
  font-weight: 500 !important;
  line-height: normal;
  color: $neutral-600 !important;
}

.MuiBadge-root {
  display: block !important;
  position: relative;
  flex-shrink: 0;
  vertical-align: middle;
}

.MuiBadge-colorSecondary {
  color: $white !important;
  background-color: $red !important;
}

.MuiDialogContent-root {
  flex: 1 1 auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.MuiTypography-colorTextSecondary {
  color: unset !important;
}

.MuiTypography-body2 {
  font-size: 1rem !important;
  font-weight: bold !important;
}

.user-description {
  text-align: left;
  font-size: 12px;
}

.profile-icon {
  font-size: 50px;
}

.cursor-pointer {
  cursor: pointer
}


.fadeIn {
  animation: 0.5s fadeIn forwards;
}

.fadeOut {
  animation: 0.5s fadeOut forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-20px, 0);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translate(0px, 0px);
  }
  to {
    transform: translate(-20px, 0);
    opacity: 0;
  }
}

.example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.responsive-image {
  width: 100%;
  min-width: 60px;
  height: auto;
}

/*@media (max-width: 400px) {*/
.nav-text {
  font-size: 12px;
}

/*}*/

@media (max-width: 300px) {
  .nav-text {
    display: none;
  }
}

/*
@media (max-width: 550px) {
    .nav-top{
        width: 100% !important;
    }
}


@media (max-width: 1200px) {
    .nav-top{
        width: 100% !important;
    }
}
*/

.wrapper {
  position: relative;
}

.wrapper span {
  position: absolute;
  right: 10px;
  margin-top: 18px;
}

.toggle-password {
  cursor: pointer;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.navbar-custom {
  box-shadow: 0px -8px 6px -6px $box-shadow;
  border-left: $border-1 $neutral-100;
  border-right: $border-1 $neutral-100;
  height: 75px !important;
  padding-bottom: 0;
  margin-bottom: 0;
  bottom: 0;
}

.people-scrollable-row {
  display: flex;
  margin-left: 0px !important;
  padding-right: 0px !important;
}

.scrolling-wrapper-flexbox {
  display: flex;
  margin-left: 0px !important;
  flex-wrap: nowrap;
  overflow-x: auto;
  /*padding-left: 10px !important; con utente "preferito"*/
  padding-left: 0 !important;
  -webkit-overflow-scrolling: touch;
}

.fixed-avatar {
  box-shadow: 7px 0 5px -5px $box-shadow;
  margin-right: 0 !important;
  z-index: 90;
  padding-right: 5px;
}

.no-scrollbar {
  scrollbar-width: none;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
}

.no-scrollbar::-webkit-scrollbar {
  width: 0;
  display: none;
}

.card {
  margin: 5px;
  flex: 0 0 auto;
  border: none !important;
}

.alt-profile {
  font-size: 14px;
}

.card-body {
  padding: 5px !important;
}

.nav-top {
  top: 0;
  position: fixed !important;
  z-index: 1 !important;
  width: 100%;
  background-color: $neutral-10;
}

@media (min-width: 768px) {
  .nav-top {
    margin-top: 65px;
    top: 0;
    position: fixed !important;
    z-index: 1 !important;
  }
}

.nav-sticky {
  top: 0;
  position: sticky;
  z-index: 1;
  width: auto;
  padding: 0;
}

.nav-top-shadow {
  top: 0;
  position: fixed !important;
  z-index: 1 !important;
  width: 100%;
  background-color: $neutral-10;
  box-shadow: 0 15px 10px -15px $box-shadow;
}

@media (min-width: 768px) {
  .nav-top-shadow {
    margin-top: 65px;
    top: 0;
    position: fixed !important;
    z-index: 1 !important;
    width: 100%;
    background-color: $neutral-10;
    box-shadow: 0 15px 10px -15px $box-shadow;
  }
}

.first-nav-top-row-shadow {
  background-color: $white !important;
  padding-top: 19.5px;
  padding-bottom: 16px;
  box-shadow: 0 15px 10px -15px $box-shadow;
}

.first-nav-top-row-shadowless {
  background-color: $white !important;
  padding-top: 19.5px;
  padding-bottom: 16px;
  height: 74px;
  /*box-shadow: 0 15px 10px -15px $box-shadow;*/
}

.first-nav-top-row-christmas {
  background-color: $white !important;
  padding-top: 19.5px;
  padding-bottom: 12px;
}

.top-of-the-dashboard {
  padding-top: 252px;
}

@media(min-width: 768px) {
  .top-of-the-dashboard {
    padding-top: 317px;
  }
}

.top-of-the-messages {
  padding-top: 164px;
  background-color: $white;
}

@media(min-width: 768px) {
  .top-of-the-messages {
    padding-top: 229px;
  }
}

.top-of-the-profile {
  padding-top: 67px;
  background-color: $white;
}

@media(min-width: 768px) {
  .top-of-the-profile {
    padding-top: 132px;
  }
}


.top-of-the-person-detail {
  padding-top: 195px;
  background-color: $white;
}

@media(min-width: 768px) {
  .top-of-the-person-detail {
    padding-top: 260px;
  }
}

.top-of-the-settings {
  padding-top: 286px;
}

@media(min-width: 768px) {
  .top-of-the-settings {
    padding-top: 351px;
  }
}

.top-of-the-message-details {
  padding-top: 85px;
}

@media(min-width: 768px) {
  .top-of-the-message-details {
    padding-top: 150px;
  }
}

.top-of-the-language {
  padding-top: 59px;
}

@media(min-width: 768px) {
  .top-of-the-language {
    padding-top: 122px;
  }
}

.top-of-the-password {
  padding-top: 67px;
}

@media(min-width: 768px) {
  .top-of-the-password {
    padding-top: 132px;
  }
}

.top-of-the-preferences {
  padding-top: 67px;
}

@media(min-width: 768px) {
  .top-of-the-preferences {
    padding-top: 132px;
  }
}

.top-of-the-vehicles {
  padding-top: 66px;
}

@media(min-width: 768px) {
  .top-of-the-vehicles {
    padding-top: 126px;
  }
}

.top-of-the-claim-detail-full {
  padding-top: 362px;
}

@media(min-width: 768px) {
  .top-of-the-claim-detail-full {
    padding-top: 427px;
  }
}

.top-of-the-claim-detail-no-value {
  padding-top: 331px;
}

@media(min-width: 768px) {
  .top-of-the-claim-detail-no-value {
    padding-top: 396px;
  }
}

.top-of-the-claim-detail-no-two-values {
  padding-top: 300px;
}

@media(min-width: 768px) {
  .top-of-the-claim-detail-no-two-values {
    padding-top: 365px;
  }
}

.top-of-the-policy-details {
  padding-top: 265px;
}

@media(min-width: 768px) {
  .top-of-the-policy-details {
    padding-top: 330px;
  }
}

.top-of-the-policy-details-without-title {
  padding-top: 281px;
}

@media(min-width: 768px) {
  .top-of-the-policy-details-without-title {
    padding-top: 346px;
  }
}

.top-of-the-contacts {
  padding-top: 286px;
}

@media(min-width: 768px) {
  .top-of-the-contacts {
    padding-top: 351px;
  }
}

.top-of-the-payment {
  padding-top: 385px;
}

@media(min-width: 768px) {
  .top-of-the-payment {
    padding-top: 450px;
  }
}

.top-of-the-payment-summary {
  padding-top: 302px;
}

@media(min-width: 768px) {
  .top-of-the-payment-summary {
    padding-top: 367px;
  }
}

.top-of-the-payment-title-detail {
  padding-top: 80px;
}

@media(min-width: 768px) {
  .top-of-the-payment-title-detail {
    padding-top: 145px;
  }
}

.top-of-the-checkout {
  padding-top: 68px;
}

@media(min-width: 768px) {
  .top-of-the-checkout {
    padding-top: 133px;
  }
}

.top-of-the-claimsHistory {
  padding-top: 124px;
}

@media(min-width: 768px) {
  .top-of-the-claimsHistory {
    padding-top: 189px;
  }
}

.top-of-the-support {
  padding-top: 190px;
}

@media(min-width: 768px) {
  .top-of-the-support {
    padding-top: 255px;
  }
}

.end-of-the-page {
  height: 70px;
}

.bottom-with-navbar {
  height: 75px;
  background-color: $white;
  position: absolute;
  left: 0;
  right: 0;
  /*border-left: $border-1 #EEE9E5;
  border-right: $border-1 #EEE9E5;*/
}

.bottom-with-footer {
  height: 40px;
  background-color: $white;
  position: absolute;
  left: 0;
  right: 0;
  /*border-left: $border-1 #EEE9E5;
  border-right: $border-1 #EEE9E5;*/
}

.login-title {
  text-align: center;
  font: normal normal 750 20px/24px Inter !important;
  letter-spacing: 0;
  color: $neutral-600;
  opacity: 1;
}

.login-subtitle {
  text-decoration: underline !important;
  font: normal normal bold 14px/19px Inter;
  letter-spacing: 0;
  opacity: 1;
}

.link-custom {
  text-decoration: underline !important;
  font: normal normal normal 14px/19px Inter;
  letter-spacing: 0;
  opacity: 1;
}


.login-subtitle:hover {
  color: $main;
}

#accountExec-email:hover {
  color: $white;
}

#accountExec-phone:hover {
  color: $white;
}

#contact-now:hover {
  color: $main;
}

.login-third-label {
  text-align: center !important;
  font: normal normal normal 12px/17px Inter !important;
  letter-spacing: 0 !important;
  color: $neutral-600 !important;
  opacity: 1 !important;
}

.custom-textfield {
  height: 50px !important;
  opacity: 1 !important;
}

.custom-textField-baseline-disable {
  height: 50px;
  pointer-events: none;
  border: $border-1 $neutral-100;
  font: normal normal normal 14px/15px Inter !important;
  letter-spacing: 0;
  color: $neutral-600 !important;
  opacity: 1;
}

.custom-textField-baseline {
  height: 50px;
  border: $border-1 $neutral-100;
  font: normal normal normal 14px/15px Inter !important;
  letter-spacing: 0;
  color: $neutral-600 !important;
  opacity: 1;
}

.custom-textField-baseline-mail {
  pointer-events: none;
  border: $border-1 $neutral-100;
  font: normal normal normal 14px/15px Inter !important;
  letter-spacing: 0;
  color: $neutral-600 !important;
  opacity: 1;
}

.custom-textField-baseline-active {
  height: 50px;
  border: $border-1 $neutral-100;
  font: normal normal normal 14px/15px Inter !important;
  letter-spacing: 0;
  color: $neutral-600;
  opacity: 1;
  text-align: center;
}

.blue {
  color: $main !important;
}

.red {
  color: $red !important;
}

._2izcf {
  /* ICONA BUTTON SPID */
  width: 30px;
  float: left;
  border-right: 1px $white !important;
}

._2QnKd {
  /* BUTTON SPID */
  width: 90% !important;
  border-radius: 10px !important;
}

._2UEmQ {
  /* ENTRA CON SPID */
}

/*BACKGROUND HALF LEFT IMAGE LOGIN*/
.bg-image {
  /*background-image: url('./../images/background.jpg');*/
  background: transparent linear-gradient(180deg, $main-active 0%, $main-dark 100%) 0% 0% no-repeat padding-box;
  background-size: cover;
  /*background-position: left center;*/
}

.login,
.image {
  min-height: 100vh;
}


.pin {
  width: 40px;
  height: 40px;
}

#pin-input {
  opacity: 0;
  filter: alpha(opacity=0);
}

.div-pin {
  width: 0;
  overflow: hidden;
}

.dot {
  height: 12px;
  width: 12px;
  background-color: $main;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  margin-left: -5px;
  margin-top: 10px;
}

.dot-red {
  background-color: $red !important;
}

.hidden {
  visibility: hidden;
}

.pin:has(.hidden) {
  border-bottom: 2.5px solid $main;
}

.pin-red:has(.hidden) {
  border-bottom: 2.5px solid $red !important;
}

.pin-text-top {
  font: normal normal normal 14px/22px Inter;
  letter-spacing: 0;
  opacity: 1;
  color: $neutral-600 !important;
}

.pin-text-bottom {
  font: normal normal normal 12px/17px Inter;
  letter-spacing: 0;
  opacity: 1;
  color: $neutral-600 !important;
}

.row-pin {
  width: auto;
  max-width: 500px;
}

.dialog-title {
  text-align: left;
  font: normal normal bold 16px/22px Inter !important;
  letter-spacing: -0.51px;
  opacity: 1;
}

.dialog-content-text {
  text-align: left;
  font: normal normal normal 12px/16px Inter !important;
  letter-spacing: -0.1px;
  opacity: 1;
}

.noMessages {
  margin: 10% auto 5% auto;
  height: 296px;
  overflow-x: hidden;
}

.list-item {
  font-weight: bold;
  border-bottom: 1px solid $neutral-100;
  padding: 5px 5px 5px 5px;
}

.list-item-multiline {
  font-weight: bold;
  border-bottom: 1px solid $neutral-100;
}

.pin-icon {
  font-size: 18px;
  margin-top: 5px;
  margin-left: -20px;
  transform: rotate(330deg);
  color: $neutral-400;
}

.col-pin {
  width: 0 !important;
}

.swipeable-list-item__trailing-actions > * {
  overflow: unset !important;
}

.fixed-container {
  position: fixed;
  background-color: $white;
  width: 100%;
  z-index: 9999999999;
  top: 0;
}

.col-min {
  width: 30% !important;
  margin-top: 13px !important;
}

@font-face {
  font-family: 'tradeGothicNextLTPro';
  src: url('./Trade-Gothic-Next-LT-Pro.ttf') format('truetype');

}

.tradeGothicNextLTPro {
  font-family: 'tradeGothicNextLTPro', serif;
}

.header-title {
  margin-top: 4px;
  margin-bottom: 0;
  text-align: center;
  font: normal normal 600 18px/21.78px Inter;
  /*letter-spacing: 1.26px;*/
  color: $neutral-600;
  opacity: 1;
}

.no-messages-text {
  text-align: center;
  font: normal normal normal 18px/21px Inter;
  color: $neutral-400;
  opacity: 1;
}

.title-page {
  color: $neutral-600;
  text-align: left;
  letter-spacing: 1.26px;
  font-size: 28px;
  line-height: 35px;
}

.filter-btn {
  text-transform: none !important;
  font-size: 14px !important;
  font-weight: bold !important;
  min-width: 20px !important;
}

.message-type-title {
  font-size: 11px;
  text-transform: uppercase;
  color: $neutral-200;
}

.message-original-date {
  color: $neutral-400;
  font-size: 12px;
  margin-top: 11px;
}

.message-title {
  color: $neutral-600;
  font-weight: bold;
  text-align: left;
  font-size: 22px;
}

.message-content {
  color: $neutral-600;
  text-align: left;
  font-size: 14px;
}

.form-switch {
  display: inline-block;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  margin-bottom: 0 !important;
}

.form-switch i {
  position: relative;
  display: inline-block;
  margin-right: .5rem;
  width: 46px;
  height: 26px;
  background-color: $neutral-200;
  border-radius: 23px;
  vertical-align: text-bottom;
  transition: all 0.3s linear;
}

.form-switch i::before {
  content: "";
  position: absolute;
  left: 0;
  width: 42px;
  height: 22px;
  background-color: $neutral-200;
  border-radius: 11px;
  transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
  transition: all 0.25s linear;
}

.form-switch i::after {
  content: "";
  position: absolute;
  left: 0;
  width: 22px;
  height: 22px;
  background-color: $white;
  border-radius: 11px;
  box-shadow: 0 2px 2px $box-shadow;
  transform: translate3d(2px, 2px, 0);
  transition: all 0.2s ease-in-out;
}

.form-switch:active i::after {
  width: 28px;
  transform: translate3d(2px, 2px, 0);
}

.form-switch:active input:checked + i::after {
  transform: translate3d(16px, 2px, 0);
}

.form-switch input {
  display: none;
}

.form-switch input:checked + i {
  background-color: $green;
}

.form-switch input:checked + i::before {
  transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0);
}

.form-switch input:checked + i::after {
  transform: translate3d(22px, 2px, 0);
}


/*
input[type="password"] {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: 2px;
}
*/

input[type=checkbox].float-checkbox {
  float: left;
  margin: 4px 10px 0 0;
  cursor: pointer;
}


.footer-text {
  text-align: center;
  font: normal normal normal 12px/17px Inter;
  letter-spacing: 0;
  color: $neutral-400;
  opacity: 1;
}

.footer-text-login {
  text-align: center;
  font: normal normal normal 10px/8px Inter;
  letter-spacing: 0;
  color: $neutral-400;
  opacity: 1;
}

.footer-text-login-desk {
  text-align: center;
  font: normal normal normal 10px/12px Inter;
  letter-spacing: 0;
  color: $neutral-400;
  opacity: 1;
}

.footer-link {
  text-align: center;
  text-decoration: underline !important;
  font: normal normal bold 14px/19px Inter !important;
  letter-spacing: 0;
  color: $main;
  opacity: 1;
}

.menu {
  margin-top: 30px;
  position: absolute;
}

.filter-menu {
  width: 171px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.filter-menu-dashboard {
  width: 200px !important;
}

.menu-item {
  text-align: left !important;
  font: normal normal normal 14px/20px Inter !important;
  letter-spacing: 0.25px !important;
  opacity: 1 !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.paragraph-text {
  font-size: 12px;
  text-align: left;
  padding-right: 20px;
  color: $neutral-600;
}

.col-list {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.card-sign {
  border: $border-1 $box-shadow !important;
  border-radius: 20px !important;
}

.card-avatar {
  border: none;
}

.card-avatar-content {
  border: none;
  padding: 0 15px 0 5px !important;
  background-color: $neutral-10 !important;
}

.card-sing-content {
  border: none;
  padding: 0 15px 0 5px !important;
}

.avatar-text {
  margin-top: 2px;
  text-align: center;
  font: normal normal 600 10px/14px Inter;
  letter-spacing: 0;
  color: $neutral-400;
  opacity: 1;
  margin-left: auto;
  margin-right: auto;
  padding-left: 4px;
  padding-right: 2px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.avatar-text-big {
  text-align: center;
  font: normal normal 600 11px/14px Inter;
  letter-spacing: 0;
  color: $neutral-200;
  opacity: 1;
  margin-left: auto;
  margin-right: auto;
  padding-left: 4px;
  padding-right: 2px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.ellipsis-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.ellipsis-1-v2 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis-2-v2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.card-text-content {
  color: $neutral-400;
  text-align: left;
  /*line-height: 1;*/
  font-size: 14px;
  padding: 0;
  margin-bottom: 14px;
}

.card-text-mid {
  color: $neutral-400;
  text-align: left;
  line-height: 1;
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase;
  padding-top: 10px;
  padding-bottom: 7px;
}

.col-plate {
  width: auto;
  /* margin-top: 7px;*/
  margin-left: 15px;
  border: $border-1 $neutral-100;
  border-radius: 16px;
  opacity: 1;
  padding: 3px 13px 3px 8px;
}

.pointer-events-none {
  pointer-events: none;
}

.div-search {
  background: $white 0% 0% no-repeat padding-box;
  border: $border-1 $neutral-200;
  border-radius: 13px;
  opacity: 1;

}

.input-search {
  width: 100%;
  height: 25px;
  border: none;
  margin-left: -5px;
  text-align: left;
  font: normal normal bold 13px/29px Inter;
  letter-spacing: 0;
  color: $main;
  outline: none;
  padding: 0 0 0 0 !important;
}

.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-out {
  opacity: 1;
  animation-name: fadeOutOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1s;
}

@keyframes fadeOutOpacity {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.text-normal-detail {
  color: $neutral-400;
  text-align: left;
  font: normal normal normal 14px/22px Inter;
}

.text-bold-detail {
  text-align: left;
  font: normal normal bold 16px/22px Inter;
}

.title-filter-item {
  padding-left: 16px !important;
  padding-right: 16px !important;
  text-align: left;
  font: normal normal normal 14px/16px Inter;
  letter-spacing: -0.1px;
  color: $neutral-200;
  opacity: 1;
}

.filter-item {
  text-align: left;
  font-size: 14px !important;
  letter-spacing: -0.1px;
  opacity: 1;
  padding: 10px 16px 10px 16px !important;
  min-height: 32px !important;
}

.menu-avatar-item {
  margin-top: 15px;
  margin-bottom: 15px;
}

.filter-btn-alt {
  text-transform: none !important;
  font-size: 14px !important;
  min-width: 20px !important;
}

.filter-btn-2 {
  height: 20px !important;
  padding: 0 0 0 0 !important;
  text-align: right;
  margin-top: -5px !important;
}

@media (max-width: 1200px) {
  body {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
}

.wallet-description {
  font-size: 14px;
  color: $main;
  line-height: 19px;
}

.personalDocs-title {
  text-align: center;
  color: $neutral-400;
  font-weight: bold;
  font-size: 18px;
}

.personalDocs-col {
  border: $border-1 $neutral-400;
  border-radius: 40px;
  padding: 20.98px 15.75px 20px 16px !important;
  margin: 0 auto;
}

.black {
  color: $neutral-600 !important;
}

.darkgrey {
  color: $neutral-400 !important;
}

.grey {
  color: $neutral-200 !important;
}

.reload-btn {
  text-align: center;
  font: normal normal normal 18px/21px Inter;
  color: $main !important;
  opacity: 1;
}

.row-border {
  padding-right: 0 !important;
  padding-left: 0 !important;
  /*border-left: $border-1 #EEE9E5 !important;
  border-right: $border-1 #EEE9E5 !important;*/
}

.full-height {
  min-height: 100vh;
  /*
  border-left: $border-1 #EEE9E5 !important;
  border-right: $border-1 #EEE9E5 !important;
   */
}

.with-scroll-height {
  height: 100%;
  /*
  border-left: $border-1 #EEE9E5 !important;
  border-right: $border-1 #EEE9E5 !important;
   */
}

.branch-icon {
  margin-top: 5px;
  text-align: left;
}

@media (max-width: 768px) {
  .trash-button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 8px 9px;
    border: none;
    cursor: pointer;
  }
}

@media (min-width: 768px) {
  .trash-button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 8px 9px;
    border: none;
    cursor: pointer;
  }
}

.delete-small {
  line-height: 32px;
  border: none;
  cursor: pointer;
  background: $red;
  color: $white;
  border-radius: 21px;
  padding: 0 12px 1px 12px;
  font-weight: bold;
  font-size: 14px;
}

.delete-small-absolute {
  position: absolute;
  bottom: 17px;
  right: 19.5px;
}

.not-favourite-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -10px;
  left: -10px;
  border: none;
  cursor: pointer;
  border-radius: 58px;
  height: 40px;
  width: 40px;
  background: $neutral-200;
  padding-bottom: 3px;
  transition: ease-out 200ms;
}

.not-favourite-button:active {
  box-shadow: 2724px 0 0 0 $box-shadow inset;
}

.favourite-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -10px;
  left: -10px;
  border: none;
  cursor: pointer;
  border-radius: 58px;
  height: 40px;
  width: 40px;
  background: $main;
  padding-bottom: 3px;
  transition: ease-out 200ms;
}

.favourite-button:active {
  box-shadow: 2724px 0 0 0 $box-shadow inset;
}

.selected-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 0;
  border: none;
  cursor: pointer;
  padding-bottom: 3px;
  transition: ease-out 200ms;
  background: transparent;
}

/*.favourite-button:hover {
    position: absolute;
    top: -10px;
    left: -10px;
    border: none;
    cursor: pointer;
    border-radius: 58px;
    height: 40px;
    width: 40px;
    background: $neutral-200;
}*/

.radio-custom {
  padding: 5px 0 0 0 !important;
}

.responsive-iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.background-exit {
  z-index: 1;
  position: absolute;
  top: 10px;
  right: 10px;
  text-align: right;
  border-radius: 50%;
  background: $white 0 0 no-repeat padding-box;
  opacity: 1;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  width: 45px;
  height: 45px;
}

.nav-action {
  padding-top: 10px !important;
  padding-bottom: 25px !important;
  color: $neutral-600;
}

.red-action {
  padding-top: 10px !important;
  padding-bottom: 25px !important;
  color: $red !important;
}

.spid-button {
  width: 274px;
  height: 50px;
  background-image: url('./../images/SPID_button.png');
  background-repeat: no-repeat;
  background-size: contain;
}

.cie-button {
  width: 274px;
  height: 50px;
  background-image: url('./../images/cie-button.png');
  background-repeat: no-repeat;
  background-size: contain;
}

.linear-gradient {
  background: $transparent-linear-gradient;
}

/*video {
    max-width: 100% !important;
    width: 100% !important;
}*/

video {
  @media (max-width: 768px) {
    & {
      /*height: 100vh !important;
      width: 100% !important;*/
      object-fit: cover !important;
      position: absolute !important;
      left: 0 !important;
    }
  }
}

video {
  @media (min-width: 768px) {
    & {
      max-width: 100% !important;
      width: 100% !important;
      height: 100vh !important;
    }
  }
}

.boxShadow {
  box-shadow: 0 5px 20px 0 $box-shadow;
}


.overZIndex {
  z-index: 10 !important
}

.footer-link-no-decoration {
  text-decoration: none;
  color: $neutral-400;
}

.slider-wrapper {
  display: none !important;
}

.carousel {
  text-align: initial !important;
  margin-top: 8px !important;
  margin-bottom: -30px !important;
}

.carousel .thumbs-wrapper {
  margin: 0 !important;
  flex-wrap: nowrap !important;
  overflow-x: auto !important;
}

.carousel .thumb {
  transition: border .15s ease-in;
  display: inline-block;
  margin-right: 0 !important;
  white-space: nowrap;
  overflow: hidden;
  border: none !important;
  padding: 2px;
}

.carousel img {
  width: 100%;
  display: inline-block;
  pointer-events: none;
  height: 100%;
  border-radius: 10px;
}

.thumb {
  /*width: 142px !important;
  height: 142px !important;*/
  width: 100% !important;
  /*height: 97px !important;*/
  vertical-align: middle !important;
}

ul.thumbs.animated, ul.thumbs {
  padding-inline-start: 0 !important;
  margin-bottom: 5px !important;
}

ul.thumbs.animated, ul.thumbs {
  display: contents;
  flex-direction: column;
}

ul.thumbs.animated.dynamic, ul.thumbs.dynamic {
  display: flex;
  flex-direction: row;
}

.MuiBadge-root.upload-badge span {
  /*margin-top: 10px;
  margin-right: 50px;*/
  position: absolute;
  top: 10px;
}

.div-under-list {
  padding-top: 0 !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  width: 100% !important;
}

.MuiTypography-body2 {
  font-weight: 500 !important;
}

.div-masked {
  padding-top: 12px;
  border-radius: 16px;
  background: $neutral-10;
  box-shadow: 0 1px 2px 0 $box-shadow inset;
  margin: 16px;
}

.exit-button {
  width: 40px;
  cursor: pointer;
}

.dialog-main-container {
  margin: 0 !important;
  width: 100% !important;
  max-width: 100% !important;
}

.dialog-form-title-1 {
  text-align: left;
  font-size: 20px;
  color: $neutral-600;
  font-weight: 700;
}

.dialog-form-title-2 {
  text-align: left;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: $neutral-400;
}

.dialog-form-title-3 {
  text-align: left;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: $neutral-200;
}

.dialog-form-field {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: $neutral-400;
}

.dialog-form-value {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: $neutral-600;
}

.dialog-form-subtitle {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: $main;
}

.dialog-parameter {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: $neutral-400;
}

.dialog-textfield-label {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: $neutral-200;
}

.dialog-notes {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: $neutral-400;
}


/*
* MOST USED BUTTONS CLASSES --------------
*/

.outline-button {
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
  border-radius: 16px !important;
  border: $border-2 $main !important;
  background: $white;
  height: 48px !important;
  color: $main !important;
  text-transform: none !important;
}

.outline-button-multiple {
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
  border-radius: 16px !important;
  border: $border-2 $main !important;
  background: $main-10 !important;
  color: $main !important;
  text-transform: none !important;
  padding: 20px !important;
}

.outline-filled-button {
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
  border-radius: 16px !important;
  border: $border-2 $main !important;
  background: $main-10 !important;
  height: 48px !important;
  color: $main !important;
  text-transform: none !important;
}

.primary-button {
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
  border-radius: 16px !important;
  background: $main !important;
  height: 48px !important;
  color: $white !important;
  text-transform: none !important;
  border: none !important;
}

.disable-button {
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
  border-radius: 16px !important;
  background-color: $neutral-200 !important;
  height: 48px !important;
  color: $white !important;
  text-transform: none !important;
  border: none !important;
}

.outline-disable-button {
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
  border-radius: 16px !important;
  background-color: $white !important;
  height: 48px !important;
  color: $neutral-200 !important;
  text-transform: none !important;
  border: $border-2 $neutral-200 !important;
}

.outline-disable-button-multiple {
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
  border-radius: 16px !important;
  background: $white !important;
  color: $neutral-200 !important;
  text-transform: none !important;
  border: $border-2 $neutral-200 !important;
  padding: 20px !important;
}

.close-button {
  height: 48px !important;
  padding: 14px 0 16px 0 !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
  background-color: $main !important;
  border-radius: 16px !important;
  border: none !important;
}

.close-button-no-padding {
  height: 48px !important;
  padding: 0 !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
  background-color: $main !important;
  border-radius: 16px !important;
  border: none !important;
}

.close-button-disabled {
  height: 48px !important;
  padding: 15px 59px 17px 59px !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
  background-color: $neutral-200 !important;
  border-radius: 16px !important;
  border: none !important;
}

.outline-delete-button {
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
  border-radius: 16px !important;
  background-color: $white !important;
  height: 48px !important;
  color: $red !important;
  text-transform: none !important;
  border: $border-2 $red !important;
}

.outline-delete-button-disable {
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
  border-radius: 16px !important;
  background: $box-shadow-light !important;
  height: 48px !important;
  color: $neutral-200 !important;
  text-transform: none !important;
  border: $border-2 $neutral-200 !important;
}

.transparent-button {
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
  border-radius: 16px !important;
  border: none !important;
  background: $main-10 !important;
  height: 48px !important;
  color: $main !important;
  text-transform: none !important;
}

/*
* ------------------------------------------
*/

.mobile-button-width {
  width: 100% !important;
}

.desktop-button-width {
  max-width: 300px !important;
}

.thumb-pdf {
  width: 100px;
  height: 100px;
  border-radius: 4px;
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;
  box-shadow: 0 3px 10px 0 $box-shadow;
}

.login-otp-error {
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.back-to-login-link {
  color: $white;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.wide-background-mobile {
  background-image: url('../images/wide-background-mobile.svg');
  background-position: center;
  height: 330px;
}

.wide-background-desktop {
  background-image: url('./../images/wide-background-desktop.svg');
  background-position: center;
  height: 330px;
}

.mywide-background-mobile {
  background-image: url('../images/mywide-background-mobile.svg');
  background-position: center;
  height: 330px;
}

.mywide-background-desktop {
  background-image: url('./../images/mywide-background-desktop.svg');
  background-position: center;
  height: 330px;
}

.login-otp-error {
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.back-to-login-link {
  color: $white;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}

.background-loading-spinner {
  background: url('./../images/spinner.svg') center center no-repeat;
  background-size: 50px;
  padding: 0;
}

.spinner-desktop {
  height: 100vh;
  width: 100%;
}

.spinner-img {
  height: 32px;
}

.spinner-img-inner-button {
  height: 20px;
}

.selected-user {
  padding: 16px 64px 18px 64px;
  margin-bottom: 16px;
  border-radius: 8px;
  background: $neutral-100;
  border: $border-1 $neutral-100;
}

.not-selected-user {
  padding: 16px 64px 18px 64px;
  margin-bottom: 16px;
  border-radius: 8px;
  border: $border-1 $neutral-100;
  background: $white;
}

.div-grid {
  position: relative;
  width: 46%;
  border-radius: 24px;
  clip-path: content-box;
  aspect-ratio: 1;
}

.div-grid-thumb {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 24px;
  clip-path: content-box;
}

.div-grid-full-desktop {
  position: relative;
  width: 32%;
}

.div-grid-full-mobile {
  position: relative;
  width: 100%;
}

.image-grid {
  box-shadow: 0px 5px 8px 0px rgba(0, 34, 56, 0.10);
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.image-fit {
  object-fit: cover;
  object-position: center;
}

.add-icon {
  background-color: $neutral-100;
  width: 46%;
  border-radius: 24px;
  transition: ease-out 200ms;
  aspect-ratio: 1;
}

.add-document-icon {
  padding: 20px;
  background-color: $main-10;
  width: 100%;
  border: $border-2 $main;
  border-radius: 24px;
  transition: ease-out 200ms;
}

.add-document-icon-small {
  padding: 20px;
  background-color: $main-10;
  width: 100%;
  border: $border-2 $main;
  border-radius: 16px;
  transition: ease-out 200ms;
}

.add-document-icon-disable {
  padding: 20px;
  background: $box-shadow-light;
  width: 100%;
  border: $border-2 $box-shadow;
  border-radius: 24px;
  transition: ease-out 200ms;
}

.add-active-icon:active {
  background: $neutral-200 !important;
}

.remove-document-icon {
  padding: 10px 59px 11px 59px;
  background-color: $white;
  width: 100%;
  border: $border-2 $main;
  border-radius: 16px;
  transition: ease-out 200ms;
}

.remove-document-icon-disable {
  padding: 10px 59px 11px 59px;
  background: $box-shadow-light;
  width: 100%;
  border: $border-2 $box-shadow;
  border-radius: 16px;
  transition: ease-out 200ms;
}

.text-transform-none {
  text-transform: none !important;
}

.favourite-icon-div {
  display: flex;
  width: 28px;
  height: 28px;
  border-radius: 58px;
  background: $main;
}

.favourite-icon {
  margin: 0 auto;
}

.personal-documents-select {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  background: $neutral-100;
  border-radius: 24px;
  gap: 8px;
  padding-top: 26px;
  padding-bottom: 26px;
  position: relative;
  transition: ease-out 200ms;
}

.personal-documents-select-title {
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: $neutral-400 !important;
}

.select-div-list {
  top: 10px;
  position: absolute;
  height: auto;
  background: $white;
  border-radius: 24px;
  width: 100%;
  box-shadow: 0 5px 20px 0 $box-shadow;
  transition: ease-out 200ms;
  z-index: 2;
}

.MuiDialog-paperWidthSm {
  border-radius: 24px !important;
}

.div-camera > div {
  position: static !important;
  height: 100% !important;
}

.custom-mail-field {
  color: $neutral-600 !important;
  padding-top: 18.5px;
  padding-bottom: 18.5px;
  align-items: center;
}

.custom-border-mail:before {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  margin-top: -10px;
  border-top: $border-1 $box-shadow;
}

.custom-title-mail {
  color: $neutral-400 !important;
  font-size: 12px !important;
  font-weight: 400;
  line-height: 1;
  text-decoration-style: unset;
}

.custom-container-mail {
  text-align: left;
}

.custom-mail-remove {
  display: flex;
  justify-content: flex-end;
}

.button-confirm-red {
  color: $red !important;
  text-transform: unset !important;
  font-weight: bold !important;
}

.button-cancel-grey {
  color: $neutral-200 !important;
  text-transform: unset !important;
  font-weight: bold !important;
}

.custom-img-mail {
}

.custom-mail-text {
  padding-left: 0 !important;
  display: flex;
  align-items: center;
  height: 27px !important;
  color: $neutral-600;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.custom-disabled-mail-text {
  color: $neutral-400 !important;
}

.custom-dialog-title * {
  color: $neutral-600;
  font-weight: bold !important;
}

.custom-button-add-mail {
  padding: 5px 12px 6px 12px !important;
  border-radius: 21px !important;
  background: $main !important;
  text-transform: none !important;
  color: $white !important;
}

.redirect-button {
  height: 25px;
  padding: 5px 10px 5px 10px !important;
  border-radius: 100px !important;
  color: $white !important;
  background: $main !important;
  font-size: 12px;
  text-transform: none !important;
  box-shadow: 1px 1px 3px 0 $box-shadow !important;
}

.exit-button-white {
  position: absolute;
  right: 0;
  width: 48px;
  top: -10px;
}

.dialog-inner-content {
  border-radius: 24px;
  background-color: $white;
  padding: 0 !important;
  margin: 0 !important;
}

.document-mask {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 400px;
  transform: translate(-50%, -50%);
}

.div-frame {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.div-document {
  position: absolute;
  top: 80%;
  left: 50%;
  width: 400px;
  transform: translate(-50%, -50%);
}

.exit-button-white {
  position: absolute;
  right: 0;
  width: 48px;
  top: -10px;
}

.dialog-inner-content {
  border-radius: 24px;
  background-color: #fff;
  padding: 0 !important;
  margin: 0 !important;
}

.checkbox-text {
  text-align: left;
  font-weight: 600;
  font-size: 14px;
  line-height: 16.1px;
}

li::marker {
  color: $neutral-200;
}

.privacy-policy-container {
  padding: 0 !important;
  margin: 0 !important;
}

.absolute-center {
  left: 50%;
  transform: translate(-50%, -50%);
}

.currency-select {
  width: 100%;
  height: 56px;
  border: $border-1 $neutral-400 !important;
  border-radius: 4px;
  padding: 10px;
}

.currency-select:hover {
  border: $border-1 black;
}

.currency-select select:focus {
  box-shadow: 0 0 3pt 2pt $main;
}

.spinner-inner-select {
  left: 20%;
  top: 51%;
  transform: translate(-50%, -50%);
  z-index: 9;
}

.small-outline-button {
  color: $main;
  border: $border-1 $main;
  border-radius: 8px;
  font-size: 12px;
  background: $neutral-10;
  min-height: 32px;
  text-transform: uppercase;
  font-weight: 500;
}

.chip-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  flex-direction: row;
}

.chip-row {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  flex-direction: row;
  padding-left: 16px;
  padding-right: 16px;
}

.html-text {
  font-size: 16px;
}

.html-link {
  color: $main;
  text-decoration: underline !important;
  font-size: 16px;
}

.linear-gradient-white {
  background: linear-gradient(180deg, $linear-gradient-white 0%, $white 73.5%);
}

.card-button-container > * {
  width: 100% !important;
}

.chip-active:active {
  color: $neutral-400;
  background: $neutral-200;
}

.square-container {
  width: 100px;
  height: 100px;
  border-radius: 16px;
  clip-path: content-box;
}


.backdrop {
  backdrop-filter: blur(16px);
  background-color: $shadowBlack;
  opacity: 1;
}

.chip {
  display: flex;
  text-wrap: nowrap;
  align-items: center;
  border-radius: 120px;
  height: 35px;
  font-size: 16px;
  padding: 3px 12px 4px 12px;
  font-weight: 600;
  cursor: pointer;
  color: $neutral-400;
  background: $neutral-100;
}

.chip-selected {
  display: flex;
  text-wrap: nowrap;
  align-items: center;
  border-radius: 120px;
  height: 35px;
  font-size: 16px;
  padding: 3px 12px 4px 12px;
  font-weight: 600;
  cursor: pointer;
  color: $main;
  background: $main-10;
}

/*
DESKTOP
*/

.ChevronIcon-custom {
  border-radius: 100%;
  border: 1px solid $neutral-400;
}

.sidebar-badge {
  color: $white;
  background: $red;
  border-radius: 100%;
  width: 19px;
  height: 19px;
  font-size: 12px;
  justify-content: center;
  display: flex;
  font-weight: bold;
}

.sidebar-badge-with-number {
  color: $white;
  background: $red;
  border-radius: 50%;
  width: 19px;
  line-height: 19px;
  font-size: 12px;
  justify-content: center;
  display: flex;
  font-weight: bold;
}

.sidebar-badge-min {
  color: $white;
  background: $red;
  border-radius: 100%;
  width: 10px;
  height: 10px;
  font-size: 12px;
  justify-content: center;
  display: flex;
  font-weight: bold;
}

.desktop-avatar {
  padding: 4px !important;
  border-radius: 40px !important;
  position: relative !important;
  z-index: 1 !important;
  border: 45px !important;
  transition: transform 250ms !important;
  cursor: pointer;
}

.desktop-avatar:hover {
  transform: translateX(6px);
}

.desktop-avatar > img {
  border-radius: 29px !important;
}

.desktop-chip {
  display: flex;
  text-wrap: nowrap;
  align-items: center;
  border-radius: 120px;
  height: 35px;
  font-size: 16px;
  padding: 3px 12px 4px 12px;
  font-weight: 600;
  cursor: pointer;
  color: $neutral-400;
  background: $neutral-100;
  gap: 8px;
}

.desktop-chip-selected {
  display: flex;
  text-wrap: nowrap;
  align-items: center;
  border-radius: 120px;
  height: 35px;
  font-size: 16px;
  padding: 3px 12px 4px 12px;
  font-weight: 600;
  cursor: pointer;
  color: $main;
  background: $main-10;
  gap: 8px;
}

.desktop-chip:hover {
  background: $neutral-200;
}

.svg-main-color {
  filter: $main-svg;
}

.radialGradient {
  background: radial-gradient(157.16% 134.59% at 91.14% 0%, #7175EC 0%, #009DF5 100%);
}

.carousel-style-custom {
  width: auto !important;
  margin-right: 8px !important;
}

.avatar-scroll-container {
  &::-webkit-scrollbar {
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

html, body {
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
}

::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

::-webkit-scrollbar-track {
  border-radius: 4px;
  background: $neutral-10;
}

::-webkit-scrollbar-thumb {
  background: $neutral-200;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: $neutral-400;
}
